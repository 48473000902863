import React from 'react'

import { animated } from 'react-spring'

const ImageLogo = ({ src, ...props }) => (
  <animated.svg
    width="300px"
    height="287.53px"
    viewBox="0 0 300 287.53"
    {...props}
  >
    <defs>
      <clipPath id="prefix__a">
        <path
          fill="none"
          d="M0 .12v212.75h74.3v74.47l225.7.19V0zm115.3 172v-57.07h69.55v57.47zM74.3 74v97.82H41v-130l218-.71v205.38l-143.7-.12v-33.2l110.55.56V74.05z"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#prefix__a)">
      <image xlinkHref={src} width="300px" height="287.53px" />
    </g>
  </animated.svg>
)

export default ImageLogo
