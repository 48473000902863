import styled, { css } from 'styled-components'
import mq from '../style/mediaQueries'

const IS_DEV = process.env.NODE_ENV === 'development'

const MqDebug = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999999999;

  ${!IS_DEV &&
    css`
      display: none;
    `};

  :after {
    font-size: 2em;
    line-height: 1em;
    content: 'XS';
    background: rgba(0, 0, 0, 0.5);
    color: red;
    
    ${mq.xs` content: 'XS';`}
    ${mq.sm` content: 'SM';`}
    ${mq.md`content: 'MD';`}
    ${mq.lg` content: 'LG';`}
    ${mq.xl` content: 'XL';`}
    ${mq.xxl` content: 'XXL';`}
  }
`

export default MqDebug
