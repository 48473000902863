import React from 'react'
import PropTypes from 'prop-types'

import CapabilitiesList from './CapabilitiesList'
import ErrorBoundary from '../common/ErrorBoundary'
import ReactHtmlParser from 'react-html-parser'
import SectionTitle from '../common/SectionTitle'
import Section from '../common/Section'
import Text, { setFontSize, sizes } from '../common/Text'
import VerticalLabel from '../common/VerticalLabel'

import styled, { css } from 'styled-components'
import { rem, modularScale } from 'polished'
import { useSpring } from 'react-spring'
import { useInView } from 'react-intersection-observer'
import mq from '../../style/mediaQueries'
import { get } from 'lodash'

const config = { mass: 5, tension: 1000, friction: 200 }
let delay = 100

const Capabilities = ({ backgroundColor, items, heading, siteName, title }) => {
  const [ref, inView] = useInView({ triggerOnce: false, threshold: 0.2 })

  const fadeIn = useSpring({
    config,
    opacity: inView ? 1 : 0,
    y: inView ? 'translateY(0px)' : 'translateY(-20px)',
    from: { opacity: 0, y: 'translateY(-20px)' },
    delay,
  })

  const headingText = get(heading, 'childMarkdownRemark.html')

  return (
    <ErrorBoundary label="Capabilities">
      <Section id="capabilities" color={backgroundColor || 'peach'} ref={ref}>
        <VerticalLabel color="black">{siteName}</VerticalLabel>
        <Content>
          <div />
          <TextBox>
            <SectionTitle>{title}</SectionTitle>
            {!!headingText && (
              <Text.animatedDiv
                html
                size="lg"
                style={{ opacity: fadeIn.opacity, transform: fadeIn.y }}
              >
                {ReactHtmlParser(headingText)}
              </Text.animatedDiv>
            )}
            <Grid>
              {!!items &&
                items.map(({ id, ...props }, i) => (
                  <CapabilitiesList
                    delay={delay}
                    key={id}
                    index={i}
                    {...props}
                  />
                ))}
            </Grid>
          </TextBox>
          <div />
        </Content>
      </Section>
    </ErrorBoundary>
  )
}

Capabilities.propTypes = {
  backgroundColor: PropTypes.string,
  body: PropTypes.object,
  buttonLabel: PropTypes.string,
  buttonUrl: PropTypes.string,
  email: PropTypes.string,
  image: PropTypes.object,
  phone: PropTypes.string,
  sectionLabel: PropTypes.string,
  siteName: PropTypes.string,
}

export default Capabilities

const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    width: calc(100% - ${theme.layout.padSm});
    margin: 0 0 ${modularScale(0)};

    ${mq.lg`
      display: grid;
      grid-template-columns: ${theme.layout.gap} 1fr;
      justify-content: center;
      align-items: center;
      max-width: ${({ theme }) => theme.layout.maxWidth};
      margin: ${modularScale(1)} 0;
    `};

    > div {
      // border: 1px solid red;
      align-self: start;
    }
  `}
`

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-top: ${modularScale(0)};

  ${mq.lg`
      padding: ${modularScale(5)} ${modularScale(0)};
    `};

  b {
    font-size: ${rem(sizes.lg)};
    line-height: ${rem(sizes.lg * 3)};
  }

  h2 {
    padding-right: ${modularScale(3)};
    ${setFontSize(sizes.lg)};

    ${mq.sm`
      ${setFontSize(sizes.lg2)};
    `};
  }

  ${Text}:first-child {
    margin-bottom: ${modularScale(4)};
  }
`

const Grid = styled.div`
  width: 100%;
  margin-top: ${modularScale(3)};

  ${mq.md`
    display: grid;
    grid-template-columns: 1fr 1fr;
  `};

  ${mq.lg`
    margin-top: ${modularScale(5)};
  `};

  > div {
    ${mq.lg`
      ul {
        margin: 0;
      }

      &:nth-child(-n + 2) {
        ul {
          margin: ${modularScale(-2)} 0 ${modularScale(4)};
        }
      }
    `};

    ${mq.md`
     padding-right: ${modularScale(2)};

      &:nth-child(even) {
        padding-left: ${modularScale(1)};
      }
    `};

    ${mq.lg`
      padding-right: ${modularScale(4)};
    `};

    ${mq.xl`
      padding-right: ${modularScale(6)};
    `};
  }

  ul {
    margin: ${modularScale(-2)} 0 ${modularScale(4)};

    li {
      font-weight: 300;
    }
  }
`
