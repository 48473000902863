import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import { useSpring, animated } from 'react-spring'
import usePosition from '../../hooks/usePosition'

const calc = (x, y, left, top) => {
  if (typeof window === 'undefined') return [0, 0]
  const width = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth
  )
  const height = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight
  )
  const xRatio = window.innerWidth / left

  return [-(y - height / 2) / 80, (x - width / xRatio) / 80, 1.07]
}

const trans = (x, y, s) =>
  `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

const Card = ({ disable, children }) => {
  const ref = useRef()
  const { left, top } = usePosition(ref, true)

  const [transform, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 350, friction: 40 },
  }))

  return (
    <animated.div
      onMouseMove={({ clientX: x, clientY: y }) =>
        set({ xys: calc(x, y, left, top) })
      }
      onMouseLeave={() => set({ xys: [0, 0, 1] })}
      ref={ref}
      style={{ transform: disable ? 'none' : transform.xys.interpolate(trans) }}
    >
      {children}
    </animated.div>
  )
}

Card.propTypes = {
  children: PropTypes.node,
}

export default Card
