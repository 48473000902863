import React from 'react'
import PropTypes from 'prop-types'

import Text from '../common/Text'
import { rem } from 'polished'

function Label({ children, required, ...props }) {
  return (
    <Text
      as="label"
      size="xxs"
      bold
      color="peach"
      css={{ display: 'block', marginBottom: rem(15), zIndex: 0 }}
      {...props}
    >
      {children}
    </Text>
  )
}

Label.propTypes = {
  children: PropTypes.node,
  required: PropTypes.bool,
}

export default Label
