import React from 'react'
import PropTypes from 'prop-types'

import { SANS_SERIF } from '../../style/type'

import { setFontSize, sizes } from '../common/Text'

import styled, { css } from 'styled-components'
import mq from '../../style/mediaQueries'

const focusStyleOn = false

function InputContainer({ children, ...props }) {
  return (
    <Container showFocus={focusStyleOn} {...props}>
      {children}
    </Container>
  )
}

InputContainer.propTypes = {
  children: PropTypes.node,
}

export default InputContainer

const Container = styled.div`
  ${({ bgColor, error, valid, theme, showFocus, disableFocus }) => css`
    z-index: 1;

    ${mq.lg`
       margin-bottom: 0;
    `};

    textarea,
    select {
      appearance: none;
      border: none;
      overflow: auto;
      outline: none;
      box-shadow: none;
      border-radius: 0;
    }

    input,
    select,
    textarea {
      appearance: none;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      width: 100%;
      border-bottom: 2px solid
        ${theme.color[error ? 'red' : valid ? 'white' : 'grey']};
      padding-bottom: 5px;

      font-family: ${SANS_SERIF};
      ${setFontSize(sizes.md)};
      font-weight: 400;
      color: white;
      background-color: transparent;

      transition: border 0.5s ease-out;

      &::placeholder {
        color: rgba(255, 255, 255, 0.5);
      }

      &:focus {
        outline: none;

        ${!disableFocus &&
          css`
            border-bottom: 2px solid ${theme.color[error ? 'red' : 'peach']};
          `};
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      border: 1px solid rgba(255, 255, 255, 0.1);
      -webkit-text-fill-color: white;
      -webkit-box-shadow: 0 0 0px 1000px ${bgColor} inset;
    }
  `};
`
