import React, { useState } from 'react'

import { Formik, Form } from 'formik'
import Button from '../common/Button'
import TextInput from '../form/TextInput'
import MessageInput from '../form/MessageInput'
import Text from '../common/Text'

import styled, { css } from 'styled-components'
import * as Yup from 'yup'
import mq from '../../style/mediaQueries'
import { get } from 'lodash'

const encode = data =>
  Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')

const ContactForm = ({ color, inView }) => {
  const [success, setSuccess] = useState(false)
  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        message: '',
      }}
      validationSchema={Yup.object({
        firstName: Yup.string()
          .max(15, 'Must be 15 characters or less')
          .required('Required'),
        lastName: Yup.string()
          .max(20, 'Must be 20 characters or less')
          .required('Required'),
        phone: Yup.string()
          .matches(
            /^[2-9]\d{2}-\d{3}-\d{4}$/,
            'Invalid phone number. Format: 123-456-7890.'
          )
          .required('Required'),
        email: Yup.string()
          .email('Invalid email address')
          .required('Required'),
        message: Yup.string(),
      })}
      onSubmit={(values, { setSubmitting }) => {
        fetch('/?no-cache=1', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: encode({ 'form-name': 'contact', ...values }),
        })
          .then(() => {
            setSubmitting(false)
            setSuccess(true)
          })
          .catch(error => {
            setSubmitting(false)
          })
      }}
    >
      {({
        errors,
        isSubmitting,
        setFieldValue,
        touched,
        values,
        handleBlur,
      }) => {
        const getProps = id => {
          const touch = get(touched, id)
          const error = get(errors, id)
          return {
            bgColor: color,
            touch,
            error,
            name: id,
            value: get(values, id),
            handleChange: setFieldValue,
            handleBlur,
          }
        }

        return success ? (
          <div>
            <Text color="peach" css={{ marginBottom: '30px' }}>
              Your message has been sent.
            </Text>
            <Button lineColor="#fff" inView onClick={() => setSuccess(false)}>
              Send again
            </Button>
          </div>
        ) : (
          <StyledForm>
            <TextInput
              {...getProps('firstName')}
              columns={1}
              //abel="First Name"
              name="firstName"
              placeholder="First Name"
              required
            />
            <TextInput
              {...getProps('lastName')}
              columns={1}
              //label="Last Name"
              name="lastName"
              placeholder="Last Name"
              required
            />
            <TextInput
              {...getProps('phone')}
              columns={2}
              //label="Phone"
              name="phone"
              placeholder="Phone Number"
              required
            />
            <TextInput
              {...getProps('email')}
              columns={2}
              //label="Email"
              name="email"
              placeholder="Email Address"
              required
            />
            <MessageInput
              {...getProps('message')}
              //label="Message"
              name="message"
              placeholder="Message"
            />
            <Button
              lineColor="#fff"
              inView={inView}
              type="submit"
              //disable={isSubmitting}
            >
              Submit
            </Button>
          </StyledForm>
        )
      }}
    </Formik>
  )
}

export default ContactForm

const StyledForm = styled(Form)`
  ${({ disable, theme }) => css`
    position: relative;
    width: 100%;

    transition: opacity 0.5s ease-out;

    ${mq.lg`
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 40px 40px;
    `};

    ${disable &&
      css`
        > div:not(:last-child) {
          opacity: 0.5;
          pointer-events: none;
        }
      `};
  `};
`
