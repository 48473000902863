import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import ErrorBoundary from '../common/ErrorBoundary'
import FlickityContainer from '../common/Flickity'
import Section from '../common/Section'
import VerticalLabel from '../common/VerticalLabel'

import LandingItem from './LandingItem'

import { useInView } from 'react-intersection-observer'
import { useHover } from '@withvoid/melting-pot'
import { get } from 'lodash'
import styled from 'styled-components'

let delay = 600
const NAV_HEIGHT = 100

const Landing = ({ backgroundColor, slideshow, siteName }) => {
  const [index, setIndex] = useState(0)
  const [ref, inView] = useInView({ triggerOnce: false, threshold: 0.2 })

  useEffect(() => {
    const timeout = setTimeout(() => (delay = 0), 1500)
    return () => {
      clearTimeout(timeout)
    }
  })

  const { hover, bind } = useHover()
  let sref = useRef(null)
  const el = get(sref, 'current')
  let y = NAV_HEIGHT
  if (el) {
    const { top } = el.getBoundingClientRect()
    y = top
  }

  return (
    <ErrorBoundary label="Landing">
      <div ref={sref} />
      <Section
        color={backgroundColor || '#000'}
        ref={ref}
        style={{ zIndex: hover && y === NAV_HEIGHT ? 999999 : 9 }}
      >
        <VerticalLabel color="peach" delay={delay}>
          {siteName}
        </VerticalLabel>
        <Content>
          <FlickityContainer
            onChange={setIndex}
            options={{
              pageDots: false,
              fade: true,
              autoPlay: 4000,
              friction: 0.3,
              dragThreshold: 20,
              pauseAutoPlayOnHover: false,
              wrapAround: true,
            }}
          >
            {!!slideshow &&
              slideshow.map(({ id, ...item }, i) => (
                <LandingItem
                  active={index === i}
                  key={id}
                  bind={bind}
                  inView={inView}
                  {...item}
                />
              ))}
          </FlickityContainer>
        </Content>
      </Section>
    </ErrorBoundary>
  )
}

Landing.propTypes = {
  backgroundColor: PropTypes.string,
  body: PropTypes.object,
  heading: PropTypes.object,
  image: PropTypes.object,
  siteName: PropTypes.string,
}

export default Landing

const Content = styled.div`
  width: 100%;
`
