import React from 'react'
import PropTypes from 'prop-types'

import ReactHtmlParser from 'react-html-parser'
import Text, { setFontSize, sizes } from '../common/Text'

import { animated, useSpring } from 'react-spring'
import { useInView } from 'react-intersection-observer'
import styled, { css } from 'styled-components'
import { modularScale } from 'polished'
import mq from '../../style/mediaQueries'

const config = { mass: 5, tension: 1000, friction: 200 }
const stagger = 100

const CapabilitiesList = ({ delay, index, body, title }) => {
  const [ref, inView] = useInView({ triggerOnce: false, threshold: 0.1 })

  const d = delay + stagger * index

  const fadeInTitle = useSpring({
    config,
    opacity: inView ? 1 : 0,
    x: inView ? 'translateX(0px)' : 'translateX(-20px)',
    from: { opacity: 0, x: 'translateX(-20px)' },
    delay: d,
  })

  const fadeInText = useSpring({
    config,
    opacity: inView ? 1 : 0,
    x: inView ? 'translateX(0px)' : 'translateX(-20px)',
    from: { opacity: 0, x: 'translateX(-20px)' },
    delay: d + 300,
  })

  return (
    <Container ref={ref}>
      <Text.animatedDiv
        style={{ opacity: fadeInTitle.opacity, transform: fadeInTitle.x }}
      >
        <b>{title}</b>
      </Text.animatedDiv>
      <Text.animatedDiv
        html
        style={{ opacity: fadeInText.opacity, transform: fadeInText.x }}
      >
        {ReactHtmlParser(body.childMarkdownRemark.html)}
      </Text.animatedDiv>
    </Container>
  )
}

CapabilitiesList.propTypes = {
  body: PropTypes.object,
  delay: PropTypes.number,
  title: PropTypes.string,
}

export default CapabilitiesList

const ColText = styled(Text.animatedDiv)``

const Container = styled(animated.div)`
  li {
    ${setFontSize(sizes.sm, 1.6)};
    font-weight: 400 !important;
    padding-left: ${modularScale(2)};

    &:first-child {
      margin-top: ${modularScale(0)};
    }

    ${mq.xl`
        ${setFontSize(sizes.md, 1.6)};
    `};
  }

  > div:first-child {
    margin-bottom: ${modularScale(-6)};
    width: 100%;

    b {
      ${setFontSize(sizes.md)};

      ${mq.xl`
        ${setFontSize(sizes.lg)};
      `};
    }
  }

  > div:last-child {
    ${setFontSize(sizes.xs, 1.6)};

    p {
      ${setFontSize(sizes.sm, 1.6)};
    }

    ${mq.xl`
      ${setFontSize(sizes.md, 1.6)};

      p {
        ${setFontSize(sizes.md, 1.6)};
      }
    `};
  }
`
