import React, { useEffect, useState, Fragment } from 'react'
import PropTypes from 'prop-types'

import ContactForm from '../common/ContactForm'
import Logo from '../common/StaticLogo'
import Img from 'gatsby-image'
import ErrorBoundary from '../common/ErrorBoundary'
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser'
import Section from '../common/Section'
import SectionTitle from '../common/SectionTitle'
import Text, { setFontSize, sizes } from '../common/Text'
import VerticalLabel from '../common/VerticalLabel'

import mq from '../../style/mediaQueries'
import { animated, useSpring } from 'react-spring'
import styled, { css } from 'styled-components'
import { modularScale } from 'polished'
import { useInView } from 'react-intersection-observer'
import { get, isNull } from 'lodash'
import { useMedia } from 'use-media'

const config = { mass: 5, tension: 1000, friction: 200 }
let delay = 100
const stagger = 100

function transform(node, index) {
  // convert <ul> to <ol>
  if (node.type === 'tag' && node.name === 'strong') {
    node.name = 'b'
    return convertNodeToElement(node, index, transform)
  }
}

const Contact = ({ backgroundColor, body, image, siteName, title }) => {
  const isWide = useMedia({ minWidth: 769 })
  const [ref, inView] = useInView({ triggerOnce: false, threshold: 0.2 })
  const [logoRef, logoInView] = useInView({
    triggerOnce: false,
    threshold: 0.5,
  })
  const [init, setInit] = useState(false)

  const fadeInText = useSpring({
    config,
    opacity: inView ? 1 : 0,
    y: inView ? 'translateY(0px)' : 'translateY(-10px)',
    from: { opacity: 0, y: 'translateY(-10px)' },
    delay: delay,
  })

  const fadeInImage = useSpring({
    config,
    opacity: inView ? 1 : 0,
    y: inView ? 'translateY(0px) scale(1)' : 'translateY(-10px) scale(0.95)',
    from: { opacity: 0, y: 'translateY(-10px) scale(0.95)' },
    delay: delay + stagger * 3,
  })

  const fadeInLogo = useSpring({
    config,
    opacity: logoInView ? 1 : 0,
    y: logoInView ? 'translateY(0px)' : 'translateY(-10px)',
    from: { opacity: 0, y: 'translateY(-10px)' },
    delay: delay,
  })

  useEffect(() => {
    let timeout = null
    if (inView) timeout = setTimeout(() => setInit(true), 1500)
    return () => {
      if (!isNull(timeout)) clearTimeout(timeout)
    }
  })

  const Wrapper = isWide ? Fragment : Expand
  const bodyText = get(body, 'childMarkdownRemark.html')

  return (
    <ErrorBoundary label="Contact">
      <StyledSection
        id="contact"
        color={backgroundColor || 'blueAlt'}
        ref={ref}
        key={init}
      >
        <VerticalLabel color="peach">{siteName}</VerticalLabel>
        <TopGrid>
          <Top>
            <SectionTitle white>{title}</SectionTitle>
          </Top>
          <Content>
            <TextBox>
              {!!bodyText && (
                <Text.animatedDiv
                  linkColor="white"
                  light
                  white
                  html
                  style={{ ...fadeInText, transform: fadeInText.y }}
                >
                  {ReactHtmlParser(bodyText, { transform })}
                </Text.animatedDiv>
              )}
            </TextBox>
            <Wrapper>
              <ImageContainer
                style={{ ...fadeInImage, transform: fadeInImage.y }}
              >
                <ContactForm
                  color={backgroundColor || 'blueAlt'}
                  inView={inView}
                />
              </ImageContainer>
            </Wrapper>
          </Content>
        </TopGrid>
      </StyledSection>
      <Section>
        <LogoContainer ref={logoRef} style={fadeInLogo}>
          <Logo color="peach" />
        </LogoContainer>
      </Section>
    </ErrorBoundary>
  )
}

Contact.propTypes = {
  backgroundColor: PropTypes.string,
  body: PropTypes.object,
  buttonLabel: PropTypes.string,
  buttonUrl: PropTypes.string,
  email: PropTypes.string,
  image: PropTypes.object,
  phone: PropTypes.string,
  sectionLabel: PropTypes.string,
  siteName: PropTypes.string,
}

export default Contact

const LogoContainer = styled(animated.div)`
  width: 100%;
  height: auto;
  min-width: 20px;
  max-width: 30px;
  margin: ${modularScale(3)} 0;
`

const StyledSection = styled(Section)`
  flex-direction: column;
align-items: center;sec
`

const TopGrid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${modularScale(4)} 0;

  ${mq.lg`
    margin: ${modularScale(6)} 0;
  `};
`

const Top = styled.div`
  ${({ theme }) => css`
    padding-left: calc(${theme.layout.padSm} / 2);
    width: 100%;

    ${mq.lg`
        padding-left: ${theme.layout.gap};
        margin-bottom: ${modularScale(4)};
     `};
  `}
`

const Content = styled.div`
  ${({ theme }) => css`
    width: calc(100% - ${theme.layout.padSm});

    ${mq.lg`
      display: grid;
      grid-template-columns: ${theme.layout.gap} 1fr 1fr ${theme.layout.gap};
      grid-template-areas: '. txt img .';
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: ${({ theme }) => theme.layout.maxWidth};
    `};

    > div {
      align-self: start;
    }
  `}
`

const TextBox = styled.div`
  ${({ theme }) => css`
    grid-area: txt;
    display: flex;
    flex-direction: column;
    margin-bottom: ${modularScale(4)};
    padding-right: ${modularScale(2)};

    ${mq.lg`
    margin: 0;
    justify-content: space-between;
  `};

    ${mq.xl`
  `};

    p {
      ${setFontSize(sizes.sm, 1.6)};

      ${mq.xl`
      ${setFontSize(sizes.md, 1.6)};
    `};

      b {
        display: block;
        padding-top: ${modularScale(1)};
        font-weight: 700;
        color: ${theme.color.peach};
      }
    }

    a {
      color: #fff;
      font-weight: 700;
    }

    ${Text}:not (:last-child) {
      margin-bottom: ${modularScale(0)};
    }
  `};
`

const Expand = styled.div`
  ${({ theme }) => theme.mixin.breakout};
`

const ImageContainer = styled(animated.div)`
  grid-area: img;

  padding: 0 calc(${({ theme }) => theme.layout.gap} / 2);

  ${mq.xl`
    padding-left: calc(${({ theme }) => theme.layout.gap} / 2);
  `};
`

const Image = styled(Img)`
  width: 100%;
  max-height: 600px;
`
