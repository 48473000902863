import React from 'react'
import PropTypes from 'prop-types'

import Text from '../common/Text'
import { rem } from 'polished'

function ErrorText({ children, show, ...props }) {
  return (
    <Text
      css={{
        opacity: show ? 1 : 0,
        marginTop: rem(5),
        height: rem(20),
        pointerEvents: 'none',
        zIndex: 0,
      }}
      size="xs"
      color="red"
      {...props}
    >
      {children}
    </Text>
  )
}

ErrorText.propTypes = {
  children: PropTypes.node,
  show: PropTypes.string,
}

export default ErrorText
