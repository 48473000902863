import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Card from '../common/Card'
import ErrorBoundary from '../common/ErrorBoundary'
import Img from 'gatsby-image'
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser'
import Section from '../common/Section'
import SectionTitle from '../common/SectionTitle'
import Text, { setFontSize, sizes } from '../common/Text'
import VerticalLabel from '../common/VerticalLabel'

import mq from '../../style/mediaQueries'
import styled, { css } from 'styled-components'
import { modularScale } from 'polished'
import { color } from '../../style/theme'
import { animated, useSpring } from 'react-spring'
import { useInView } from 'react-intersection-observer'
import ImageLogo from './ImageLogo'
import { get } from 'lodash'

export function transform(node, index) {
  const { type, name } = node
  if (type === 'tag' && name === 'em') {
    return convertNodeToElement({ ...node, name: 'span' }, index, transform)
  }
}

const config = { mass: 5, tension: 1000, friction: 200 }
const delay = 100
const stagger = 100

const Owner = ({
  backgroundColor,
  body,
  collaboratorLabel,
  collaborators,
  logoImage,
  images,
  siteName,
  title,
}) => {
  const [ref, inView] = useInView({ triggerOnce: false, threshold: 0.2 })
  const [refLogo, inViewLogo] = useInView({
    triggerOnce: false,
    threshold: 0.2,
  })
  const [refList, inViewList] = useInView({
    triggerOnce: false,
    threshold: 0.2,
  })
  const [activeIndex, setIndex] = useState(0)

  const fadeIn0 = useSpring({
    config,
    opacity: inView ? 1 : 0,
    y: inView ? 'translateY(0px) scale(1)' : 'translateY(-20px) scale(0.95)',
    from: { opacity: 0, y: 'translateY(-20px) scale(0.95)' },
    delay: delay,
  })

  const fadeIn1 = useSpring({
    config,
    opacity: inView ? 1 : 0,
    y: inView ? 'translateY(0px) scale(1)' : 'translateY(-20px) scale(0.95)',
    from: { opacity: 0, y: 'translateY(-20px) scale(0.95)' },
    delay: delay + stagger,
  })

  const fadeIn2 = useSpring({
    config,
    opacity: inView ? 1 : 0,
    y: inView ? 'translateY(0px) scale(1)' : 'translateY(-20px) scale(0.95)',
    from: { opacity: 0, y: 'translateY(-20px) scale(0.95)' },
    delay: delay + stagger * 2,
  })

  const fades = [fadeIn0, fadeIn1, fadeIn2]

  const fadeInText = useSpring({
    config,
    opacity: inView ? 1 : 0,
    y: inView ? 'translateY(0px)' : 'translateY(-20px)',
    from: { opacity: 0, y: 'translateY(-20px)' },
    delay: delay + stagger * 3,
  })

  const fadeInList = useSpring({
    config,
    opacity: inView ? 1 : 0,
    y: inView ? 'translateY(0px)' : 'translateY(-20px)',
    from: { opacity: 0, y: 'translateY(-20px)' },
    delay: delay + stagger * 4,
  })

  const fadeInLogo = useSpring({
    config,
    opacity: inViewLogo ? 1 : 0,
    from: { opacity: 0 },
    delay,
  })

  const bodyText = get(body, 'childMarkdownRemark.html')
  const image = get(logoImage, 'fluid.src')
  return (
    <ErrorBoundary label="Owner">
      <Section color={backgroundColor || 'white'} z={999}>
        <VerticalLabel color="blue">{siteName}</VerticalLabel>

        <Content ref={ref}>
          <Title>{title}</Title>

          {!!images &&
            images.map((item, i) => {
              const fade = fades[i]
              return (
                <ImageContainer
                  i={i}
                  key={`img${i}`}
                  style={{
                    ...fade,
                    transform: fade.y,
                    zIndex: activeIndex === i ? 4 : 1,
                  }}
                  onMouseOver={() => setIndex(i)}
                >
                  <Card>
                    <Image
                      i={i}
                      fluid={item.fluid}
                      backgroundColor={color.imageBg}
                    />
                  </Card>
                </ImageContainer>
              )
            })}

          <TextBox>
            {!!bodyText && (
              <Text.animatedDiv
                html
                light
                style={{ ...fadeInText, transform: fadeInText.y }}
              >
                {ReactHtmlParser(bodyText, { transform })}
              </Text.animatedDiv>
            )}
            <Collabs ref={refList}>
              <CollabsGrid style={{ ...fadeInList, transform: fadeInList.y }}>
                <Text html>
                  <span>
                    <b>{collaboratorLabel}</b>
                  </span>
                  <ColText>
                    {!!collaborators &&
                      collaborators.map(({ id, name, url }) => (
                        <Text as="a" key={id} href={url} target="_blank">
                          {name}
                        </Text>
                      ))}
                  </ColText>
                </Text>
              </CollabsGrid>
              {!!image && (
                <Card>
                  <div ref={refLogo}>
                    <ImageLogo src={image} style={fadeInLogo} />
                  </div>
                </Card>
              )}
            </Collabs>
          </TextBox>
        </Content>
      </Section>
    </ErrorBoundary>
  )
}

Owner.propTypes = {
  backgroundColor: PropTypes.string,
  body: PropTypes.object,
  collaboratorLabel: PropTypes.string,
  collaborators: PropTypes.array,
  images: PropTypes.array,
  title: PropTypes.string,
  siteName: PropTypes.string,
}

export default Owner

const LINE_HEIGHT = 1.6

const ColText = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 0 ${modularScale(4)};
`

const Title = styled(SectionTitle)`
  grid-area: title;
`

const Content = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: ${0} 1fr 1fr 1fr ${0};
    grid-template-areas:
      '. title title img2 .'
      '. img0 img1 img2 .'
      '. txt txt txt .';
    justify-content: center;
    align-items: center;
    margin: ${modularScale(4)} 0;
    width: calc(100%);

    ${mq.lg`
      width: 100%;
      max-width: ${({ theme }) => theme.layout.maxWidth};
      grid-template-columns: ${theme.layout.gap} 1fr 1fr 1fr ${
      theme.layout.gap
    };

    margin: ${modularScale(6)} 0;
    `};

    > div:first-child {
      padding-left: calc(${theme.layout.padSm});
    }
  `};
`

const TextBox = styled.div`
  ${({ theme }) => css`
    grid-area: txt;
    height: 100%;
    padding: ${modularScale(4)} ${theme.layout.gapSm} 0;

    ${mq.lg`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    justify-content: start;
    grid-column-gap: ${({ theme }) => theme.layout.gap};
    padding: ${modularScale(5)} ${modularScale(0)};
  `};

    span {
      color: ${theme.color.blueAlt} !important;
    }

    p {
      &:not(:last-child) {
        margin-bottom: ${modularScale(0)};
      }

      ${setFontSize(sizes.sm, LINE_HEIGHT)};

      ${mq.xl`
      ${setFontSize(sizes.md, LINE_HEIGHT)};

    `};
    }

    small {
      p {
        ${setFontSize(sizes.xs, LINE_HEIGHT)};

        &:first-child {
          padding-top: ${modularScale(2)};
        }

        ${mq.xl`
      ${setFontSize(sizes.sm, LINE_HEIGHT)};
    `};
      }
    }
  `};
`

const ImageContainer = styled(animated.div)`
  ${({ i, theme }) => css`
    grid-area: ${`img${i}`};
    align-self: start;
    width: 100%;
  `}
`

const Image = styled(Img)`
  width: 100%;
  max-height: 400px;

  ${({ i }) => css`
    ${i === 2 &&
      css`
        max-height: calc(400px + ${modularScale(6)});
        object-fit: cover;
      `};
  `}
`

const Collabs = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  margin-top: ${modularScale(2)};

  ${mq.lg`
    align-items: start;
    height: 100%;
    margin-top: 0;
  `};

  svg {
    width: 100%;
    height: auto;
    max-width: 320px;
    transform: translateY(${modularScale(5.5)});
    align-self: center;

    ${mq.lg`
      max-width: 100%;
      transform: translateY(${modularScale(8.5)});
    `};
  }
`

const CollabsGrid = styled(animated.div)`
  display: flex;
  width: 100%;

  > * {
    width: 100%;
  }

  a {
    font-weight: 400;

    &:first-child {
    }
  }

  > div {
    padding-right: 10px;

    span {
      ${setFontSize(sizes.xs, LINE_HEIGHT)};

      ${mq.xl`
        ${setFontSize(sizes.sm, LINE_HEIGHT)};
      `};
    }

    > div {
      margin-top: 10px;

      > a {
        ${setFontSize(sizes.xs, LINE_HEIGHT)};
      }
    }
  }
`
