import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import Button from '../common/Button'
import Card from '../common/Card'
import ErrorBoundary from '../common/ErrorBoundary'
import Img from 'gatsby-image'
import ReactHtmlParser from 'react-html-parser'
import Section from '../common/Section'
import SectionTitle from '../common/SectionTitle'
import Text, { setFontSize, sizes } from '../common/Text'
import VerticalLabel from '../common/VerticalLabel'

import { animated, useSpring } from 'react-spring'
import { color } from '../../style/theme'
import styled, { css } from 'styled-components'
import { modularScale } from 'polished'
import { useInView } from 'react-intersection-observer'
import mq from '../../style/mediaQueries'
import { get } from 'lodash'

const config = { mass: 5, tension: 1000, friction: 200 }
let delay = 100
const stagger = 100

const About = ({
  backgroundColor,
  body,
  buttonLabel,
  buttonUrl,
  image,
  sectionLabel,
  siteName,
}) => {
  const [ref, inView] = useInView({ triggerOnce: false, threshold: 0.2 })
  const [buttonRef, buttonInView] = useInView({
    useInView: true,
    threshold: 0.4,
  })

  const fadeInText = useSpring({
    config,
    opacity: inView ? 1 : 0,
    y: inView ? 'translateY(0px) scale(1)' : 'translateY(-10px) scale(0.95)',
    from: { opacity: 0, y: 'translateY(-10px) scale(0.95)' },
    delay: delay + stagger,
  })

  const fadeInRight = useSpring({
    config,
    opacity: inView ? 1 : 0,
    y: inView ? 'translateY(0px) scale(1)' : 'translateY(-10px) scale(0.95)',
    from: { opacity: 0, y: 'translateY(-10px) scale(0.95)' },
    delay: delay + stagger * 2,
  })

  useEffect(() => {
    const timeout = setTimeout(() => (delay = 0), 1500)
    return () => {
      clearTimeout(timeout)
    }
  })

  const bodyText = get(body, 'childMarkdownRemark.html')

  return (
    <ErrorBoundary label="About">
      <Section id="about" ref={ref} color={backgroundColor || 'white'}>
        <VerticalLabel color="blue">{siteName}</VerticalLabel>
        <Content>
          <div />
          <TextBox>
            <SectionTitle>{sectionLabel}</SectionTitle>
            {!!bodyText && (
              <Text.animatedDiv
                html
                light
                style={{ ...fadeInText, transform: fadeInText.y }}
              >
                {ReactHtmlParser(bodyText)}
              </Text.animatedDiv>
            )}
            <Row style={{ ...fadeInText, transform: fadeInText.y }}>
              <a href={buttonUrl}>
                <InquireButton ref={buttonRef}>
                  <Button inView={buttonInView}>{buttonLabel}</Button>
                </InquireButton>
              </a>
            </Row>
          </TextBox>
          <div />
          <CapabilitiesBg />
          <ImageContainer style={{ ...fadeInRight, transform: fadeInRight.y }}>
            {!!image && (
              <Card>
                <Image fluid={image.fluid} backgroundColor={color.imageBg} />
              </Card>
            )}
          </ImageContainer>
        </Content>
      </Section>
    </ErrorBoundary>
  )
}

About.propTypes = {
  backgroundColor: PropTypes.string,
  body: PropTypes.object,
  buttonLabel: PropTypes.string,
  buttonUrl: PropTypes.string,
  email: PropTypes.string,
  image: PropTypes.object,
  phone: PropTypes.string,
  sectionLabel: PropTypes.string,
  siteName: PropTypes.string,
}

export default About

const CapabilitiesBg = styled.div`
  position: absolute;
  bottom: 0%;
  left: 0;
  width: 100%;
  height: ${modularScale(7)};
  background: ${({ theme }) => theme.color.peach};

  ${mq.lg`
    display: none;
  `};
`

const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin: ${modularScale(4)} 0;
    width: calc(100% - ${theme.layout.padSm});

    ${mq.lg`
      display: grid;
      grid-template-columns: ${theme.layout.gap} 1fr ${theme.layout.gap} 1fr;
      justify-content: center;
      align-items: center;
      max-width: ${theme.layout.maxWidth};
      margin: ${modularScale(6)} 0;
    `};

    // border: 1px solid CapabilitiesBg;

    > div {
      // border: 1px solid red;
      align-self: start;
    }
  `}
`

const InquireButton = styled.div`
  margin: ${modularScale(2)} 0 ${modularScale(4)};

  ${mq.xl`
     margin-top: 0;
    `};
`

const TextBox = styled.div`
  ${mq.lg`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  `};

  @media (min-width: 1200px) {
    padding: ${modularScale(5)} 0;
  }

  ${Text}:first-child {
    margin-bottom: ${modularScale(0)};

    ${mq.xl`
      margin-bottom: ${modularScale(4)};
    `};
  }

  ${Text}:last-child {
    max-width: 60%;
  }

  p {
    ${setFontSize(sizes.sm, 1.6)};

    ${mq.xl`
      ${setFontSize(sizes.md, 1.6)};
    `};
  }
`

const ImageContainer = styled(animated.div)`
  justify-content: center;
  width: 100%;

  ${mq.lg`
    padding: 0 50px 0 0;
  `};

  ${mq.xl`
    padding: 0 50px;
  `};
`

const Image = styled(Img)`
  left: 50%;
  width: 100%;
  max-width: calc(100% - ${({ theme }) => theme.layout.padSm});
  transform: translateX(-50%);

  ${mq.sm`
     max-width: 300px;
  `};

  ${mq.lg`
    max-width: 100%;
    transform: none;
    left: auto;
  `};
`

const Row = styled(animated.div)`
  width: 100%;
  margin-top: ${modularScale(3)};

  ${mq.xl`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `};
`
