import React from 'react'
import PropTypes from 'prop-types'

import ErrorBoundary from '../common/ErrorBoundary'
import Card from '../common/Card'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import MagicGrid from 'magic-grid-react'
import Section from '../common/Section'
import SectionTitle from '../common/SectionTitle'
import VerticalLabel from '../common/VerticalLabel'

import { animated, useTrail } from 'react-spring'
import styled, { css } from 'styled-components'
import { modularScale } from 'polished'
import { color } from '../../style/theme'
import { useInView } from 'react-intersection-observer'
import { get } from 'lodash'
import mq from '../../style/mediaQueries'

const config = { mass: 5, tension: 1000, friction: 200 }
let delay = 100

const Work = ({ backgroundColor, projects, siteName, title }) => {
  const len = !!projects ? projects.length : 0

  const [ref, inView] = useInView({ triggerOnce: false, threshold: 0.2 })

  const trail = useTrail(len, {
    config,
    opacity: inView ? 1 : 0,
    y: inView ? 'translateY(0px) scale(1)' : 'translateY(-10px) scale(0.95)',
    from: { opacity: 0, y: 'translateY(-10px) scale(0.95)' },
    delay,
  })

  return (
    <ErrorBoundary label="Work">
      <Section id="work" ref={ref} color={backgroundColor || '#000'} z={998}>
        <VerticalLabel color="orange">{siteName}</VerticalLabel>
        <Content>
          <Title white>{title}</Title>

          <Grid>
            <MagicGrid gutter={20} items={len} useMin>
              {!!trail &&
                trail.map(({ y, ...rest }, index) => {
                  const item = projects[index]
                  const image = get(item, 'image')
                  const h = get(image, 'file.details.image.height') || 400
                  return (
                    <ImageContainer
                      key={`img${index}`}
                      style={{
                        ...rest,
                      }}
                    >
                      <animated.div
                        style={{
                          transform: y,
                        }}
                      >
                        <Link to={`/${item.project.slug}`}>
                          <Card>
                            <Image
                              i={index}
                              fluid={image.fluid}
                              height={h}
                              backgroundColor={color.imageBg}
                            />
                          </Card>
                        </Link>
                      </animated.div>
                    </ImageContainer>
                  )
                })}
            </MagicGrid>
          </Grid>
        </Content>
      </Section>
    </ErrorBoundary>
  )
}

Work.propTypes = {
  backgroundColor: PropTypes.string,
  body: PropTypes.object,
  projects: PropTypes.array,
  title: PropTypes.string,
  siteName: PropTypes.string,
}

export default Work

const Title = styled(SectionTitle)`
  grid-area: title;
  padding-left: 10px;

  ${mq.lg`
    padding-left: 15px;
 `};
`

const Content = styled(animated.div)`
  ${({ theme }) => css`
    margin: ${modularScale(4)} 0;
    width: calc(100% - ${theme.layout.gapSm});

    ${mq.lg`
      display: grid;
      grid-template-columns: ${theme.layout.gap} 1fr ${theme.layout.gap};
      grid-template-areas:
        '. title .'
        '. grid .';
      width: 100%;
      max-width: calc(${theme.layout.maxWidth} + 40px);
      margin: ${modularScale(6)} 0;
    `};
  `}
`

const Grid = styled.div`
  ${({ theme }) => css`
    grid-area: grid;
  `}
`

const ImageContainer = styled(animated.div)`
  ${({ theme }) => css`
    min-width: calc(50% - 20px);

    ${mq.lg`
      min-width: calc(33% - 20px);
     `};
  `};
`

const Image = styled(Img)`
  ${({ i }) => css`
    ${i === 2 &&
      css`
        object-fit: cover;
      `};
  `}
`
