import React from 'react'
import PropTypes from 'prop-types'

import Card from '../common/Card'
import Img from 'gatsby-image'
import Text, { setFontSize, sizes } from '../common/Text'
import ReactHtmlParser from 'react-html-parser'

import { animated, useSpring } from 'react-spring'
import { color } from '../../style/theme'
import styled, { css } from 'styled-components'
import { modularScale } from 'polished'
import mq from '../../style/mediaQueries'
import { fluidType } from '../../utils/fulidType'

const config = { mass: 5, tension: 1000, friction: 200 }

let delay = 600
const stagger = 100

const LandingItem = ({ active, bind, text, image, inView }) => {
  const fadeInText = useSpring({
    config,
    opacity: inView && active ? 1 : 0,
    y:
      inView && active
        ? 'translateY(0px) scale(1)'
        : 'translateY(-10px) scale(0.95)',
    from: { opacity: 0, y: 'translateY(-10px) scale(0.95)' },
    delay: delay + stagger,
  })

  const fadeInRight = useSpring({
    config,
    opacity: inView ? 1 : 0,
    y: inView ? 'translateY(0px) scale(1)' : 'translateY(-10px) scale(0.95)',
    from: { opacity: 0, y: 'translateY(-10px) scale(0.95)' },
    delay: delay + stagger * 2,
  })

  return (
    <Content active={active}>
      <div />
      <TextBox style={{ ...fadeInText, transform: fadeInText.y }}>
        <StyledText center serif html size="lg">
          {ReactHtmlParser(text.childMarkdownRemark.html)}
        </StyledText>
      </TextBox>
      <animated.div
        style={{ ...fadeInRight, transform: fadeInRight.y }}
        {...bind}
      >
        {!!image && (
          <Card getPosition disable={!active}>
            <Image fluid={image.fluid} backgroundColor={color.imageBg} />
          </Card>
        )}
      </animated.div>
    </Content>
  )
}

LandingItem.propTypes = {
  text: PropTypes.object,
  image: PropTypes.object,
}

export default LandingItem

const Content = styled.div`
  ${({ active, theme }) => css`
    width: 100%;
    max-width: ${({ theme }) => theme.layout.maxWidth};
    pointer-events: none;

    ${active &&
      css`
        pointer-events: all;
      `};

    ${mq.lg`
      display: grid;
      grid-template-columns: ${theme.layout.gap} 1fr ${theme.layout.gap} 1fr;
      justify-content: center;
      align-items: start;
    `};
  `}
`

const StyledText = styled(Text.animatedDiv)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: calc(100% - ${({ theme }) => theme.layout.padSm} / 2);

  p {
    ${setFontSize(sizes.lg)};
    width: 100%;
    max-width: 520px !important;
    text-align: center;

    ${mq.xs`
         ${setFontSize(sizes.md)};
    `};

    ${mq.sm`
         ${setFontSize(sizes.lg)};
    `};

    ${mq.md`
        max-width: 400px;
        ${setFontSize(35)};
    `};

    ${mq.lg`
        ${setFontSize(sizes.lg)};
        max-width: 350px;
    `};

    ${mq.xl`
        max-width: 400px;
        ${setFontSize(24)};
    `};
  }

  b,
  strong {
    font-weight: 400;
    color: ${({ theme }) => theme.color.red};
  }
`

const TextBox = styled(animated.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: start;
  grid-column-start: 2;
  grid-column-end: 4;
  background-color: #fff;
  padding: ${modularScale(4)} ${modularScale(0)};
  width: 100%;
  min-height: 240px;

  ${mq.md`
    padding: ${modularScale(4)} ${modularScale(0)};
    min-height: 55%;
  `};

  ${mq.lg`
    padding: ${modularScale(4)} ${modularScale(0)};
    min-height: 65%;
  `};

  ${mq.xl`
  padding: ${modularScale(4)} ${modularScale(3)};
    min-height: 75%;
  `};
`

const Image = styled(Img)`
  width: 100%;
  height: auto;
`
