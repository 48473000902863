import React, { Component } from 'react'
import { PropTypes } from 'prop-types'

import About from '../components/about/About'
import Capabilities from '../components/capabilities/Capabilities'
import Contact from '../components/contact/Contact'
import Landing from '../components/landing/Landing'
import Layout from '../components/Layout'
import Nav from '../components/nav/Nav'
import Owner from '../components/owner/Owner'
import Work from '../components/work/Work'
import MqDebug from '../components/MqDebug'

import config from '../utils/siteConfig'
import scrollTo from '../utils/scrollTo'
import { get, isNull } from 'lodash'
import { graphql } from 'gatsby'

class IndexPage extends Component {
  _timeout = null

  componentDidMount() {
    this._timeout = setTimeout(this._checkForHash, 150)
  }

  componentDidUpdate(prevProps) {
    const {
      location: { hash },
    } = this.props
    if (prevProps.location.hash !== hash) {
      this._checkForHash()
    }
  }

  componentWillUnmount() {
    this._reset()
  }

  _reset = () => {
    if (!isNull(this._timeout)) {
      clearTimeout(this._timeout)
      this._timeout = null
    }
  }

  _checkForHash = () => {
    this._reset()

    const { location, navigate } = this.props
    const hash = get(location, 'hash')

    if (!!hash) {
      scrollTo(hash.substring(1), false)

      const num = hash.indexOf('#') > -1
      if (num) {
        navigate('/', { replace: false })
      }
    }
  }

  render() {
    const { data, siteName } = this.props

    const getSectionData = id => {
      return {
        ...get(data, `allContentful${id}.edges[0].node`),
        siteName,
      }
    }

    const site = getSectionData('Site')
    const navLinks = get(site, 'nav')
    const socialLinks = get(site, 'socialLinks')
    const about = getSectionData('AboutSection')

    return (
      <Layout site={site}>
        <Nav {...site} navLinks={navLinks} isIndex />

        <Landing {...getSectionData('LandingSection')} />

        <About {...about} />

        <Capabilities {...getSectionData('CapabilitiesSection')} />

        <Work {...getSectionData('WorkSection')} />

        <Owner {...getSectionData('OwnerSection')} />

        <Contact
          {...getSectionData('ContactSection')}
          socialLinks={socialLinks}
          phone={about.phone}
        />

        <MqDebug />
      </Layout>
    )
  }
}

IndexPage.propTypes = {
  siteName: PropTypes.string,
}

IndexPage.defaultProps = {
  siteName: config.siteTitle,
}

export const query = graphql`
  query {
    allContentfulSite {
      edges {
        node {
          siteTitle
          tagLine
          siteDescription {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          keywords
          nav {
            id
            title
            slug
          }
          socialLinks {
            id
            title
            url
            username
            image {
              file {
                url
                details {
                  size
                  image {
                    width
                    height
                  }
                }
              }
            }
          }
        }
      }
    }

    allContentfulLandingSection {
      edges {
        node {
          id
          backgroundColor
          slideshow {
            id
            text {
              childMarkdownRemark {
                html
              }
            }
            image {
              file {
                details {
                  image {
                    width
                    height
                  }
                }
              }
              fluid(maxWidth: 1200) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }

    allContentfulAboutSection {
      edges {
        node {
          backgroundColor
          sectionLabel
          body {
            childMarkdownRemark {
              html
            }
          }
          email
          phone
          buttonLabel
          buttonUrl
          image {
            file {
              details {
                image {
                  width
                  height
                }
              }
            }
            fluid(maxWidth: 1200) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }

    allContentfulCapabilitiesSection {
      edges {
        node {
          backgroundColor
          title
          heading {
            childMarkdownRemark {
              html
            }
          }
          items {
            id
            title
            body {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }

    allContentfulOwnerSection {
      edges {
        node {
          backgroundColor
          title
          images {
            title
            file {
              details {
                image {
                  width
                  height
                }
              }
            }
            fluid(maxWidth: 1200) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          body {
            childMarkdownRemark {
              html
            }
          }
          logoImage {
            file {
              details {
                image {
                  width
                  height
                }
              }
            }
            fluid(maxWidth: 1200) {
              src
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          collaboratorLabel
          collaborators {
            id
            name
            url
          }
        }
      }
    }

    allContentfulWorkSection {
      edges {
        node {
          backgroundColor
          title
          projects {
            id
            title
            project {
              slug
            }
            image {
              title
              file {
                details {
                  image {
                    width
                    height
                  }
                }
              }
              fluid(maxWidth: 1200) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }

    allContentfulContactSection {
      edges {
        node {
          backgroundColor
          title
          body {
            childMarkdownRemark {
              html
            }
          }
          image {
            file {
              details {
                image {
                  width
                  height
                }
              }
            }
            fluid(maxWidth: 1200) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

export default IndexPage
